import { getEmail } from './utility';
import { getShipmentField } from '../../../../../utils/shipment';
import getArrayProperty from '../../../../../utils/getArrayProperty';
import { fetchCareID } from '../shared/accertifyCareIDLogging';

export const generateAccertifyReturnRequestPayload = async (
  dialogState,
  orderDetails,
  athleteInfo,
  oktaToken,
  orderLines
) => {
  const shipments = getArrayProperty(orderDetails.shipmentsV2, 'objects');
  const { currency, omsRegionReference, orderNumber } = orderDetails;
  const { email } = athleteInfo;
  const { address } = dialogState;
  try {
    const carePrice = Object.keys(orderLines).reduce((acc, key) => {
      const orderLine = orderLines[key];
      return acc + (orderLine.linePriceInformation?.listPrice || 0) * orderLine.quantityToReturn;
    }, 0);

    const productDetails = Object.keys(orderLines).map((key) => {
      const orderLine = orderLines[key];

      return {
        productId: `${orderLine.styleNumber}-${orderLine.colorCode}`,
        qty: orderLine.quantityToReturn,
      };
    });
    const careIDResponse = await fetchCareID(orderNumber, productDetails, oktaToken);
    return {
      eventSource: 'OMOBO',
      careID: careIDResponse?.careId,
      careTransactionType: 'Request',
      careTransactionDateTime: new Date().toISOString(),
      careAmount: Number(carePrice),
      careCurrencyCode: currency,
      careCustomType: 'Return',
      athleteEmail: email,
      transactionID: orderNumber,
      transactionGeo: omsRegionReference,
      emailAddress: getEmail(dialogState),
      phoneNumber: address?.dayPhoneNumber,
      items: Object.keys(orderLines).map((key) => {
        const orderLine = orderLines[key];
        const trackingNumberForShipment = getTrackingNumberForShipment(
          shipments,
          orderLine.orderLineKey
        );
        const {
          linePriceInformation,
          quantityToReturn,
          returnReason,
          item,
          styleNumber,
          colorCode,
        } = orderLine;
        return {
          description: item?.itemDescription,
          itemID: `${styleNumber}-${colorCode}`,
          price: linePriceInformation?.listPrice,
          quantity: quantityToReturn,
          productCondition: 'new',
          shippingCompany: getShipmentField(shipments[0], 'standardCarrierAlphaCode'),
          shippingMethod: getShipmentField(shipments[0], 'actualShippingMethod'),
          shippingTrackingNumber: trackingNumberForShipment,
          originalDeliveryDateTime: new Date().toISOString(),
          pickUpLocation: `${address?.address1} ${address?.address2} ${address?.city} ${address?.state} ${address?.postalCode} ${address?.country}`,
          pickUpPersonFirstNamePrimary: address?.firstName,
          pickUpPersonLastNamePrimary: address?.lastName,
          pickUpPersonFirstNameAlternate: address?.alternateFirstName,
          pickUpPersonLastNameAlternate: address?.alternateLastName,
          careCustomReason: `${omsRegionReference}-RETURN-${returnReason?.code}`,
        };
      }),
    };
  } catch (error) {
    console.error('Error in generateAccertifyReturnRequestPayload', error);
  }
};

export const getTrackingNumberForShipment = (shipments, orderLineKey) => {
  for (const shipment of shipments) {
    for (const container of shipment.containers) {
      for (const shipmentLine of container.shipmentLines) {
        if (shipmentLine.orderLineIdentifier === orderLineKey) {
          return shipment.trackingNumber;
        }
      }
    }
  }
  return null;
};
