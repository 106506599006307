import configObj from '../../../../../utils/config';

// Function to fetch careID for a given sales order number, product ID and quantity
export const fetchCareID = async (salesOrderNumber, productDetails, oktaToken) => {
  const requestHeaders = {
    method: 'POST',
    body: JSON.stringify({ productDetails, salesOrderId: salesOrderNumber }),
    headers: {
      'authorization': oktaToken,
      'Content-Type': 'application/json',
    },
    cors: true,
  };
  try {
    const careID = await fetch(configObj.foundry.accertifyGetCareID, requestHeaders);
    return careID.json();
  } catch (error) {
    console.log('Error fetching careid:', error);
  }
};

// Function to update careID record with return order number.
export const updateReturnForCareID = async (careID, retunOrderNumber, oktaToken) => {
  const requestHeaders = {
    method: 'POST',
    headers: {
      'authorization': oktaToken,
      'Content-Type': 'application/json',
    },
    cors: true,
    body: JSON.stringify({ careId: careID, returnId: retunOrderNumber }),
  };
  try {
    return fetch(configObj.foundry.accertifyUpdateCareID, requestHeaders);
  } catch (error) {
    console.error('Error updating careid record:', error);
  }
};
